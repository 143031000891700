<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="ma-0 py-0 mt-5">
        <v-btn color="#4caf50" @click="BackToCustomer">
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.CustomerList") }}
        </v-btn>
      </v-col>
    </v-row>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.customerAcc') + titleName"
          icon="mdi-account"
        >
            <v-sheet>
                <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col lg="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    v-model="search"
                    append-icon="mdi-magnify"
                  />
                </v-col>
              </v-row>
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-account-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.add") }}</v-btn
              >
            </v-toolbar-items>
            </v-sheet>  
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getAccountList, upsertAccount, delAccount } from '@/api/account'
import { customerInfo } from '@/api/goods'
export default {
  name: "user",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.customerList") },
        { text: this.$vuetify.lang.t("$vuetify.customerAcc") },
      ],
      search:"",
      titleName: "",
      searchForm: {
          key: '',
          customer_id: '',
          skip: 0,
          limit: 10
        },
    
    };
  },
  methods: {
    BackToCustomer() {
        this.$router.push("/customers/list");
    },
    handleDialog(){},
    getData(){
        getAccountList(this.searchForm).then((res)=>{
            this.datas = [...res.items]
        })
    },
    getInfo(){
        customerInfo(this.searchForm.customer_id).then((res) => {
            this.titleName = 
                ` (` + 
                     this.$vuetify.lang.t("$vuetify.company") +
                `: ${res.item.company || res.item.company_en})`
        })
    }
  },
  mounted(){
      this.getInfo();
  }
};
</script>
